<template>
    <div class="Login">
        <div>
            <div style="height: 20.5797rem; position: relative;">
                <img style="width: 100%;" src="../assets/login_bj.png" alt="" />
                <div style="color: #fff; font-weight: 500; font-size: 1.6667rem; position: absolute; top: 40%; left: 50%; transform: translate(-50%, -50%);">欢迎登录拼团分销</div>
            </div>

            <div style="padding: 2.1739rem 3.6232rem;">
                <div style="display: flex; align-items: center; font-weight: 500; background: #f5f5f5; padding: 0 1.8116rem; border-radius: 1.087rem;">
                    <div style="display: flex; align-items: center;">
                        <span style="color: #F68624; font-size: .7246rem;">+86</span>
                        <div style="background: #d9d9d9; width: px; height: 1.1594rem; margin-left: 1.0145rem;"></div>
                    </div>
                    <el-input class="input-style-none" v-model="phoneNumber" placeholder="请输入手机号"></el-input>
                </div>

                <div style="margin-top: 1.4493rem; display: flex; align-items: center; font-weight: 500; background: #f5f5f5; padding: 0 .7246rem; border-radius: 1.087rem;">
                    <el-input class="input-style-none" v-model="password" placeholder="请输入密码"></el-input>
                </div>

                <div @click="handleClickLoginBtn" style="color: #fff; height: 2.7536rem; line-height: 2.7536rem; font-size: 1.0145rem; font-weight: bold; margin-top: 7.2464rem; text-align: center; border-radius: 1.087rem; background: #f68624;">
                    立即登录
                </div>
            </div>
        </div>

        <!-- 提示 -->
        <div v-if="isShowHint">
            <div style="z-index: 3001; color: #fff; font-weight: 400; font-size: .8696rem; position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); background: rgba(0, 0, 0, .6); border-radius: .2899rem; padding: .7246rem 1.087rem;">
                {{hintContent}}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Login",
    components: {

    },
	data() {
		return {
			// 手机号码
            phoneNumber: '',
            // 密码
            password: '',
            // 是否显示提示
            isShowHint: false,
            // 提示内容
            hintContent: '',
		}
	},
    methods: {
        /** 处理点击登录按钮 */
        handleClickLoginBtn() {
            if(this.clearStringSpace(this.phoneNumber) === "" || this.clearStringSpace(this.password) === "") {
                this.showMessage("请填写手机号码或者密码");
                return;
            } 

            this.$axios.post("/admins/waplogin", {
                telphone: this.phoneNumber,
                password: this.password,
            }).then(res => {
                // localStorage.setItem('token', res.token);
                localStorage.setItem("telphone", this.phoneNumber);
                localStorage.setItem("userInfo", JSON.stringify({...JSON.parse(localStorage.getItem("userInfo")), admin_group_id: res.admin_group_id}));
                this.$router.push({path: "/"});
            }).catch(error => {
                this.showMessage(error);
            });
        },

        /** 显示提示 */
        showMessage(message) {
            this.hintContent = message;
            this.isShowHint = true;
            const timeout = setTimeout(() => {
            this.isShowHint = false;
                clearTimeout(timeout);
            }, 1000);
        },

        /** 去掉字符串左右空格 */
        clearStringSpace(string) {
            string = string ? string : "";
            return string.trimStart().trimEnd();
        },
    },
};
</script>

<style scoped lang="scss">
    .Login {
        background: #fff;
        min-height: 100vh;
        height: 100%;
    }

    .input-style-none {
        ::v-deep input {
            border: none !important;
            background: transparent !important;
        }
    }
</style>
